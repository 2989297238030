<template>
  <div id="maindiv">
    <div id="view1">
      <div id="title">
        <h1>Welcome To<br>Yarrow Acupuncture</h1>
        <h2>Traditional Chinese Medicine</h2>
      </div>
    </div>
    <div id="mint-bar">Wellness. Balance. Relaxation.</div>
    <div id="view2">
      <h3>How does Acupuncture work?</h3>
      <p>Sixteenth-century Chinese doctors believed that illness was due to an imbalance of energy in the body known as QI. In acupuncture, disposable, stainless steel needles are used to stimulate the body's 14 major meridians, or energy-carrying channels, to resist or overcome illnesses by correcting these imbalances.</p>
      
      <h3>Is Acupuncture Safe?</h3>
      <p>In the hands of a licensed practitioner, acupuncture is a very safe, effective treatment for many acute and chronic conditions and has been used for thousands of years by many cultures.</p>
      
      <h3>Is Acupuncture Painful?</h3>
      <p>Acupuncture treatments are not painful. Acupuncture is performed with hair-thin needles that do not resemble the pain felt by a hypodermic needle. Patients often fall asleep and are in deep relaxation on the table.</p>
      
      <h3>Are there any side effects?</h3>
      <p>One of the great advantages of acupuncture is that it is very safe. Other than an occasional bruise, side effects are rare.</p>
      
      <h3>Where do the needles go?</h3>
      <p>Needles are placed at specific acupuncture points on the body. Depending on the condition the needles commonly are placed at or below the elbows, and at or below the knees. Other common areas are the back, abdomen, scalp, and ears.</p>
      
      <h3>What should I expect for my first treatment?</h3>
      <p>During the first appointment, the practitioner will take a complete health history and establish a treatment plan for the condition you report. You will be asked questions about health history and lifestyle, not only for the condition you are being treated. The practitioner will feel your pulse, look at your tongue, and palpate specific meridians to locate tender areas. The initial treatment may last between 1 and 1.5 hours, and all subsequent treatments are for 1 hour.</p>
      
      <h3>Can I receive acupuncture when I am pregnant?</h3>
      <p>Acupuncture is safe during pregnancy and can help relieve many pregnancy and postpartum conditions. Because some acupuncture points are contraindicated during pregnancy it is important to inform the practitioner if you are pregnant. Acupuncture can also assist in supporting the labor process.</p>
      
      <h3>How can I prepare for my acupuncture treatment?</h3>
      <p>The best way to prepare is to eat a snack or a light meal before treatment so your body has sufficient energy to work with. It is also important to avoid caffeine and alcohol before and after treatments. It is best to wear loose clothing so that your arms and legs are accessible. After treatment, we recommend you drink a glass of water to hydrate and stabilize your energy.</p>
      
      <h3>What are the advantages of acupuncture?</h3>
      <p>One benefit of acupuncture is that it is a drug-free way to optimize health. With drugs, people often develop a tolerance, or the need for an increased dosage to achieve the same required effect. However, this does not happen with acupuncture. In addition, acupuncture allows the practitioner to immediately examine a person's response to the treatment and adjust it if necessary.</p>
      
      <h3>Do Western medical doctors approve of acupuncture?</h3>
      <p>Yes. There are over 13,000 licensed acupuncturists in the U.S. In addition, the World Health Organization currently recognizes more than 40 medical problems that can be helped by acupuncture treatment. The FDA regulates acupuncture needles as medical devices.</p>
    </div>
    <div id="view4">
      <div id="box3">
        <h3>About Me</h3>
        <p>
          My name is Trisha Sater and I am a Licensed Acupuncturist in Maryland (L.Ac.) and a MS graduate of Five Branches University, Santa Cruz, CA.
          Diplomate of Acupuncture, NCCAOM Board Certified.
          I have a B.A. in Social Advocacy from Cal Poly Humboldt, Arcata, CA.
          In addition to my Acupuncture career, I have over 20 years of experience with Non-profit organizations.
        </p>
      </div>
      <div id="box1">
    <h3>Commonly Treated Conditions</h3>
    <ul>
      <li>Anxiety</li>
      <li>Depression</li>
      <li>Arthritis</li>
      <li>Pain Acute and Chronic</li>
      <li>Headaches/Migraines</li>
      <li>Neurological disorders</li>
      <li>Tendonitis</li>
      <li>Sciatica</li>
    </ul>
  </div>

  <div id="box2">
    <h3>Services Offered</h3>
    <ul>
      <li>Acupuncture</li>
      <li>Acupressure</li>
      <li>Reflexology</li>
      <li>Tuina Massage</li>
      <li>Cupping</li>
      <li>Gua Sha</li>
      <li>Qi Gong</li>
      <li>Dietary Planning</li>
      <li>Aromatherapy</li>
      <li>Moxibustion</li>
      <li>Infrared Light Therapy</li>
      <li>EFT Therapy</li>
    </ul>
  </div>

</div>

  <div id="view3">
    <div>
      <p>In addition to my clinic in Upperco, MD, I provide acupuncture treatments in the comfort of your own home. Many reasons can prevent you from getting out to a clinic to have health treatments. Perhaps you have an injury, pain, or disability that prevents you from being mobile. Maybe you have kids or other loved ones you can’t leave home alone. Or it could be that you simply don’t want to deal with traffic to and from an appointment.  ~Trisha</p>
    </div>
  </div>
  <div class="view5">
    <h3>To schedule an appointment contact Yarrow Acupuncture: <br><a class="link" href="mailto:yarrowacupuncture@gmail.com">yarrowacupuncture@gmail.com</a><br><a class="link" href="tel:4437891971">443-789-1971</a></h3>
  </div>
  <div id="promo">Website designed and coded by <a href="https://hirejoejocuns.netlify.app" target="_blank">Joe Jocuns</a></div>
</div>
</template>
<script>
export default {

  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

#view1 {
  background-image: url('@/../resources/yarrowlogo.jpg');
 
  height: 500px; /* Adjust the height as needed */
  border-radius: 20px; /* Adjust the border-radius as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%; /* Adjust the padding as needed */
  background-size: cover; /* Ensure the background image covers the entire area */
  background-position: center; /* Center the background image */
}

#title {
  text-align: center;
  font-size: 150%; /* Decrease the font size for better fit on mobile devices */
  color: rgb(255, 255, 255);
    font-family: 'Playfair Display', serif;
  
  
  background-color: rgb(61, 61, 66,0.7);
  border-radius: 20px;
  margin-top: 0px;
}

#mint-bar-container {
  width: 100%; /* Same width as the view1 container */
  margin-top: 5px;

  margin-bottom: 5px;
}

#mint-bar {
 
  
  background-color: rgb(77, 134, 77);
  border-radius: 10px;
  margin-top: 10px;
  font-family: "Great Vibes", cursive;
  color: #ffff;
  font-size: 300%;
  padding-top: 10px;


}

#view2 {

  border: 1px solid #858585;
  border-radius: 20px;
  background-color: #f9f9f9;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

#view2 h3{
font-size: x-large

}

#view2 p {
  
  line-height: 1.5; /* Increase line height for better readability */
  color: black;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  font-size: large  ;
  padding-left: 7px;
  padding-right: 7px;
  
}
  #view4 {
    border-radius: 20px;
    background-color: rgb(61, 61, 66);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  margin-bottom: 10px;
  }

  #view4 > h3{
    font-size: large;
  }

  #view4 > div {
    flex: 1 1 30%;
    margin-bottom: 20px; /* Vertical spacing */
    margin-right: 10px; /* Horizontal spacing */
    padding: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgb(156, 137, 58);
    color: #ffff;
    font-weight: bold;
    list-style-type: none;
    
  
  
  }

  #view4 > div:last-child {
    margin-right: 0; /* Remove right margin for the last box */
  }

  #view4 h3 {
    margin-top: 0;
  }

  #view4 p {
    margin-bottom: 0;
  }

  #view3{
    border-radius: 20px;
    background-color: rgb(26, 16, 90);
    padding: 10px;
    color: #ffff;
  font-family: 'Playfair Display', serif;
  font-size: larger; 
  font-style: italic;
  }


  .view5{
    background-color: rgb(115, 48, 48);
    padding: 20px;
    border-radius: 20px;
    color: aliceblue;
    margin-top: 10px;
  margin-bottom: 10px;
  }

  


  
  #box1 ul,
  #box2 ul,
  #box3 ul {
    list-style-type: none;
    padding-left: 0;  
  }

 
  

.link {
 border: 2px solid rgb(255, 255, 255);
 background-color: rgb(61, 61, 66);
 border-radius: 0.9em;
 padding: 0.8em 1.2em 0.8em 1em;
 transition: all ease-in-out 0.2s;
 font-size: 16px;
 color: aliceblue;
 display: inline-block;
 margin-top: 10px;
 text-decoration: none;
 
}

.link span {
 display: flex;
 justify-content: center;
 align-items: center;
 color: #fff;
 font-weight: 600;
}

.link:hover {
 background-color: rgb(85, 85, 91);
}

#promo{
  color: #d5d2d2;
}
#promo > a{
  color: #d5d2d2;
}
 
</style>