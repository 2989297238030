<template>

  <div class="home">
    <main-page> </main-page>
  </div>
</template>s

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import MainPage from '../components/MainPage.vue'

export default {
  name: 'HomeView',
  components: {
  
    MainPage
  }
}
</script>
